<template>
  <section
    class="relative w-screen h-full flex justify-center items-center content-center bg-blueGray-800"
    style="min-height:calc(100vh - 125px)"
  >
    <div class="container mx-auto h-full" style="max-width:80%">
      <div class="flex content-center items-center justify-center h-full">
        <div class="w-full lg:w-4/12">
          <div
            class="relative mx-auto flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
          >
            <div class="rounded-t mb-0 px-6 py-6">
              <div class="text-center mb-3">
                <h6 class="text-blueGray-500 font-bold text-xl">
                  {{ $t("Login") }}
                </h6>
              </div>
              <!-- <div class="btn-wrapper text-center">
              <button
                class="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                type="button"
              >
                <img alt="github" class="w-5 mr-1" src="" />
                {{ $t("Github") }}
              </button>
              <button
                class="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                type="button"
              >
                <img alt="google" class="w-5 mr-1" src="" />
                {{ $t("Google") }}
              </button>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" /> -->
            </div>
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <!-- <div class="text-blueGray-400 text-center mb-3 font-bold">
              <small>{{ $t("Or sign in with credentials") }}</small>
            </div> -->
              <form>
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-password"
                  >
                    {{ $t("Email") }}
                  </label>
                  <input
                    v-model="email"
                    type="email"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Email"
                  />
                </div>
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-password"
                  >
                    {{ $t("Password") }} </label
                  ><input
                    v-model="password"
                    type="password"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Password"
                  />
                </div>
                <!-- <div>
                  <label class="inline-flex items-center cursor-pointer"
                    ><input
                      type="checkbox"
                      class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                    /><span
                      class="ml-2 text-sm font-semibold text-blueGray-600"
                    >
                      {{ $t("Remember me") }}
                    </span>
                  </label>
                </div> -->
                <div class="text-center mt-6">
                  <button
                    @click="
                      $store.dispatch('accounts/login', {
                        user: { email, password }
                      })
                    "
                    class="text-white text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="button"
                    :disabled="!(email && password)"
                    :class="{
                      'bg-blueGray-800 active:bg-blueGray-600':
                        email && password,
                      'cursor-not-allowed bg-blueGray-400': !(email && password)
                    }"
                  >
                    <i
                      class="fas fa-circle-notch fa-spin"
                      v-if="$store.state.accounts.loading"
                    />
                    {{ $t("Login") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="flex flex-wrap mt-6 relative">
            <div class="w-1/2">
              <a href="javascript:void(0)" class="text-blueGray-200">
                <small>{{ $t("Forgot password?") }}</small>
              </a>
            </div>
            <!-- <div class="w-1/2 text-right">
              <a href="#/auth/register" class="text-blueGray-200"
                ><small>{{ $t("Create new account") }}</small>
              </a>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: ""
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$store.state.accounts?.user?.token) {
        vm.$router.push({ name: "Home" });
      }
    });
  }
};
</script>
